@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

body {
  font-family: 'Inter', sans-serif;
}

.App {
  text-align: center;
}

.background {
  background-color: #fdfefe;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 2vmin);
  color: #81B29A; /* Burada renk tekrar tanımlandı */
}

.header {
  background-color: #81B29A;
  min-height: 8vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; /* Label'ı sola, dil seçimini sağa itmek için */
  font-size: calc(5px + 2vmin);
  color: white;
  margin-top: 0;
  padding: 0 2vw; /* İçerikleri kenarlardan biraz uzakta tutmak için */
}

.headerLeftLabel {
  text-decoration: underline;
  font-size: 1.3em;
  font-weight: bold;
  color: white; /* Header sol label rengini tekrar belirledik */
}

.headerLeftLabel:hover {
  cursor: pointer;
}

.language {
  position: relative;
  display: flex;
  align-items: center;
}

.languageSelector {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.languageSelector img {
  width: 30px;
  height: 20px;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.languageOptions {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #81B29A;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: none; /* Başlangıçta gizli */
  flex-direction: column;
  z-index: 1;
}

.languageOptions.active {
  display: flex; /* Tıklandığında göster */
}

.languageOption {
  display: flex;
  align-items: center;
  padding: 0.5em 1em;
  cursor: pointer;
  color: white; /* Dil seçenekleri metin rengini belirledik */
}

.languageOption img {
  width: 25px;
  height: 15px;
  margin-right: 0.5em;
}

.languageOption:hover {
  background-color: #678e7a;
}

.label {
  color: #81B29A;
  font-size: 1em;
  margin-top: -20vh;
  font-weight: bold;
}

.label:hover {
  cursor: pointer;
  color: #81B29A;
  transition: color 0.5s;
  text-shadow: 0 0 3px #81B29A;
}

.labelText {
  color: #81B29A;
  font-size: 0.5em;
  margin-top: 1vh;
  font-weight: bold;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #81B29A;
  text-align: center;
  font-size: small;
  padding-bottom: 1vh;
  padding-top: 1vh;
  opacity: 0.9;
}

.footerLabel:hover {
  cursor: pointer;
  color: #81B29A;
  transition: color 0.5s;
}
